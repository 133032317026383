import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useReadLocalStorage } from 'usehooks-ts';
import { roleBasedRoutes, roles } from '@/constants/roles';
import { PUBLIC_ROUTES, Routes } from '@/constants/routes';
import { ACCESS_TOKEN } from '@/constants/storage';
import { useRole } from './useRole';

export const useRoleBasedRouter = () => {
  const [allowed, setAllowed] = useState(false);
  const router = useRouter();
  const token = useReadLocalStorage(ACCESS_TOKEN);
  const role = useRole();

  const isPublicRoute = (url: string) => {
    return some(
      PUBLIC_ROUTES.filter((r) => r !== Routes.HOME),
      (route) => {
        return url.startsWith(route);
      },
    );
  };

  const isRouteHasSameRole = useCallback(
    (url: string) => {
      return url.startsWith(`/${role}`);
    },
    [role],
  );

  function isCommerceURL(path: string) {
    const pattern = /^\/commerce\/[^/]+$/;
    return pattern.test(path);
  }

  const isUserAuth = !isEmpty(token);

  const authCheck = useCallback(
    (url: string) => {
      if (Routes.MAINTENANCE === router.route || Routes.COMMERCE === router.route) {
        return;
      }
      if (router.route === Routes.REFERRAL) {
        const searchParam = new URLSearchParams(document.location.search);
        const code = searchParam.get('code');
        router.push({
          pathname: Routes.SIGN_UP,
          query: { code },
        });
      } else {
        const path = url.split('?')[0];

        if (!isCommerceURL(path) && !isUserAuth && !PUBLIC_ROUTES.includes(path as Routes)) {
          const isExistedRoute = Object.values(Routes).includes(router.asPath as Routes);

          router.push({
            pathname: Routes.SIGN_IN,
            ...(isExistedRoute && { query: { returnUrl: router.asPath } }),
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router, isUserAuth, token],
  );

  const handleRouteChange = useCallback(
    (url: string) => {
      const routeRole = url.split('/')[1];
      const hasRouteRole = roles.includes(routeRole);
      const isRoot = [...roleBasedRoutes, Routes.HOME].includes(url);
      const notFound = [Routes.NOT_FOUND, Routes.ERROR].includes(url as Routes);

      switch (true) {
        case Routes.MAINTENANCE === router.route:
          setAllowed(true);
          break;
        case Routes.COMMERCE === router.route:
          setAllowed(true);
          break;
        case isPublicRoute(url):
          setAllowed(true);
          break;
        case isRoot && !isUserAuth:
          router.replace(Routes.SIGN_IN);
          break;
        case isUserAuth && hasRouteRole && !isRouteHasSameRole(url):
          setAllowed(false);
          router.replace(url.replace(routeRole, role));
          break;
        case isUserAuth && !hasRouteRole && !notFound && !isRouteHasSameRole(url):
          setAllowed(false);
          router.replace(`/${role}${url}`);
          break;
        case isUserAuth && notFound && !isRouteHasSameRole(url):
          setAllowed(false);
          router.replace(`/${role}`);
          break;
        case isUserAuth && (isRoot || (isRouteHasSameRole(url) && notFound)):
          setAllowed(false);
          router.replace(`/${role}${Routes.OVERVIEW}`);
          break;
        default:
          setAllowed(true);
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRouteHasSameRole, isUserAuth, role],
  );

  useEffect(() => {
    handleRouteChange(router?.pathname);
    authCheck(router.asPath);

    router.events.on('routeChangeComplete', authCheck);
    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
      router.events.off('routeChangeComplete', authCheck);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, router?.pathname]);

  return { allowed, isReady: router.isReady };
};
