import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { axiosClient, url } from '@/api';
import { AxiosError } from 'axios';
import { ErrorObject, PaymentType } from '../common.types';

export interface useTutorialStepsResponse {
  screenNumber: string;
  headline: string;
  body: string;
  guide: string | null;
  link: string | null;
  rateTtlSeconds: number;
}

export function useTutorialSteps<TSelectData = useTutorialStepsResponse[], TError = AxiosError<ErrorObject>>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['GetTutorialSteps'],
    () => axiosClient.get(url.tutorialController.tutorialSteps),
    options,
  );
}
